/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";

  function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  //console.log ("" + getCookieValue("apd_securitytoken"));

  $(document).on('submit', '#send_img_upload', function() {

    //UploadMove();
    $("#uploadProgressShow").show();
    var id = $("#sende_image").val();
    var pic = $("#imgInp").prop("files")[0];
    var method = 'upload_image';
    var form_data = new FormData();
    form_data.append("PIC", pic);
    form_data.append("ID", id);
    form_data.append("METHOD", method);
    $.ajax({
      url: 'ajax/form/member-upload.php',
      dataType: "json",
      contentType: false,
      cache: false,
      processData: false,
      data: form_data,
      type: "POST",
      success: function(data) {
        if (data.status) {
          success_message(data.msg);
          $("#uploadProgressShow").hide();
          var new_image = 'users/user-images/thumb/' + data.image;
          $("#img_blob").attr("src", new_image);
          //  $("#usr_img").attr("src", new_image);
          var img_container = $("#user_round_img");
          img_container.css("width", 120);
          img_container.css("height", 120);
          img_container.css("border-radius", "50%");
          var u = document.getElementById("user_round_img");

          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, data.x, data.y, data.width, data.height, 0, 0, 120, 120);
          };
          img.src = data.base64;
        } else {
          warning_message(data.msg);
          $("#uploadProgressShow").hide(500);
        }
      },
      error: function(xhr, resp, text) {
        console.log(xhr, resp, text);
      }
    });
    return false;
  });

  function UploadMove() {
    $("#uploadProgressShow").show(500);
    var elem = document.getElementById("uploadBar");
    var width = 1;
    var id = setInterval(frame, 240);

    function frame() {
      if (width >= 100) {
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + '%';
      }
    }
  }
  //delete userimage
  $(document).on('click', '#delete_image', function() {
    var id = $(this).attr('data-id');
    warn_delete(id);
  });

  function warn_delete(id) {
    swal({
      icon: "info",
      title: "User-Image löschen?",
      text: "Dein Profilbild wirklich löschen?",
      buttons: {
        cancel: "abbrechen",
        senden: {
          text: "löschen",
          value: 'delete'
        }
      }
    }).then((value) => {
      switch (value) {
        case "delete":
          //funktion aufrufen
          delete_user_image(id);
          break;
      }
    });
  }

  function delete_user_image(id) {
    $.post("ajax/form/member-upload.php", {
        'ID': id,
        'METHOD': 'delete_user_image'
      },
      function(data) {
        if (data.status) {
          success_message(data.msg);
          var new_image = 'assets/images/user.jpg';
          $("#img_blob").attr("src", new_image);
          $("#usr_img").attr("src", new_image);
          var img_container = $("#user_round_img");
          img_container.css("width", 120);
          img_container.css("height", 120);
          img_container.css("border-radius", "50%");
          var u = document.getElementById("user_round_img");

          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, 0, 0, 350, 350, 0, 0, 120, 120);
          };
          img.src = new_image;
        } else {
          warning_message(data.msg);
        }
      });
  }
  //FILEUPLOAD
  function readURL(input) {

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $('#img_blob').attr('src', e.target.result);
        $('#img_blob').hide();
        $('#img_blob').fadeIn(650);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  $("#imgInp").change(function() {
    readURL(this);
  });
  //validate Fileupload
  $("#imgInp").change(function() {
    var fileExtension = ['jpeg', 'jpg', 'png', 'gif'];
    if ($.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1) {
      alert("Dieser Dateityp wird nicht unterstützt. unterstützt werden " + fileExtension.join(', '));
      $("#imgInp").val("");
    }
  });

  //modal
  $('#editImageModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var recipient = button.data('whatever');
    var modal = $(this);

    $.post("ajax/form/member-upload.php", {
        'ID': recipient,
        'METHOD': 'read_user_image'
      },
      function(data) {
        if (data.status) {
          $("#senden_edit_image").html('<button type="button" id="btn_send_edit" data-userid="' + data.userid + '" data-imgid="' + data.image_id + '" class="btn btn-primary">Bild speichern</button>');
          var new_image = 'users/user-images/thumb/' + data.image;
          $("#image_content").html('<img id="edit_image" src="' + new_image + '" class="mx-auto d-block" alt="ArtPicture Design User-Image"/>');
          modal.find('.modal-title').html('<p class="prem fa fa-info-circle">&nbsp;<b class="text-secondary">Bild bearbeiten</b></p>');

          //canvas cropper
          //cropper.destroy();
          var img = new Image();
          img.src = data.image;
          img.onload = function() {
            $("#cropper_image").attr('src', data.image);
            //$("#show_image").attr('src', data.image);
            var coef = 0;
            img.width > img.height ? coef = calculateCoeff(img.width, "width") : coef = calculateCoeff(img.height, "height");
            createCropper((coef * img.width), (coef * img.height), data.image);
            //createCropper(data.width,(data.height,data.image);
          };
        } else {
          //  warning_message(data.msg);
          $("#image_content").html('<p class="text-center">' + data.msg + '</p>');
          modal.find('.modal-title').html('<span class="dan fa fa-exclamation-triangle">&nbsp; Kein Bild vorhanden!</span>');
          $("#senden_edit_image").empty();
        }
      });
  });
  //edit image
  $(document).on('click', '#btn_send_edit', function() {
    var user_id = $(this).attr('data-userid');
    var image_id = $(this).attr('data-imgid');
    $.post("ajax/form/member-upload.php", {
        'ID': user_id,
        'image_id': image_id,
        'crop_data': $("#crop_data").val(),
        'METHOD': 'update_grop_image'
      },
      function(data) {
        if(data.status) {
          $("#editImageModal").modal('toggle');
          success_message(data.msg);
          var img_container = $("#user_round_img");
          img_container.css("width", 120);
          img_container.css("height", 120);
          img_container.css("border-radius", "50%");
          var u = document.getElementById("user_round_img");

          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, data.x, data.y, data.width, data.height, 0, 0, 120, 120);
          };
          img.src = data.image;
        } else {
          warning_message(data.msg);
        }
      });
  });

  function calculateCoeff(img_value, property) {
    var x = 0;
    property == "width" ? x = 250 : x = 250;
    return ((x * 100) / img_value) * 0.01;
  }

  /////////////////////////////////////////////////////////////////////////////////////
  function createCropper(width, height, db_image) {

    var image = document.getElementById('cropper_image');

    var img_container = $("#show_image");
    img_container.css("width", width);
    img_container.css("height", height);
    img_container.css("border-radius", "50%");

    var x;
    var y;
    var canvas_width;
    var canvas_height;

    var cropper = new Cropper(image, {
      viewMode: 2,
      aspectRatio: 1 / 1,
      movable: false,
      zoomable: false,
      minContainerHeight: height,
      minContainerWidth: width,
      minCanvasHeight: height,
      minCanvasWidth: width,

      crop(event) {
        x = event.detail.x;
        y = event.detail.y;
        canvas_width = event.detail.width;
        canvas_height = event.detail.height;
        //canvas
        var c = document.getElementById("show_image");
        c.width = c.scrollWidth;
        c.height = c.scrollHeight;
        var ctx = c.getContext("2d");
        var img = new Image();
        img.onload = function() {
          ctx.drawImage(img, x, y, canvas_width, canvas_height, 0, 0, height, width);
        };
        img.src = db_image;
        var bildschnitt = x + "," + y + "," + canvas_width + "," + canvas_height;
        $("#crop_data").val(bildschnitt);
      }
    });
  }

  //Message Handle
  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }
}); //document

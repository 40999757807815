/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";


  //Sende Kommentar
  $(document).on('submit', '#send-kommentar-form', function() {
    $('#send-kommentar-form .add_repeat').html('<input type="text" name="repeat_email" />');
    var form_data = JSON.stringify($(this).serializeObject());
    send_blog_formular(form_data);
    return false;
  });

  //Sende Edit Kommentar
  $(document).on('submit', '.send-kommentar-edit', function() {
    $('.send-kommentar-edit .add_repeat').html('<input type="text" name="repeat_email" />');
    var form_data = JSON.stringify($(this).serializeObject());
    send_blog_formular(form_data);
    return false;
  });

  //Ajax Handle
  function send_blog_formular(form_data) {
    $.ajax({
      url: "ajax/form/user-blog.php",
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(data) {
        if (data.status) {
          success_message(data.msg);
          if (data.sendForm) {
            $("#send-kommentar-form").trigger("reset");
          } else if (data.komEdit) {
            $('#user_kommentar' + data.id + '').html(data.kommentar);
            $('#collapseKommentar' + data.id + '').collapse('toggle');
          }

        } else {
          warning_message(data.msg);
        }
      },
      error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
      }
    });
  }
  //delete Kommentar
  $(document).on('click', '.delete_user_kommentar', function() {
    var id = $(this).attr('data-id');
    var token = $(this).attr('data-token');
    warn_delete_kommentar(id, token);
  }); //button

  function delete_user_kommentar(id, token) {
    $.post("ajax/form/user-blog.php", {
      id: id,
      'method': 'delete_user_kommentar',
      'token' : token
    }, function(data) {
      if (data.status) {
        success_message(data.msg);
        $('#show_kommentar_box' + data.id + '').empty();
      } else {
        warning_message(data.msg);
      }
    });
  }

  function warn_delete_kommentar(id, token) {
    swal({
      icon: "info",
      title: "Diesen Eintrag löschen?",
      text: "Diesen Eintrag wirklich löschen?",
      buttons: {
        cancel: "abbrechen",
        senden: {
          text: "löschen",
          value: 'delete'
        }
      }
    }).then((value) => {
      switch (value) {
        case "delete":
          //funktion aufrufen
          delete_user_kommentar(id, token);
          break;
      }
    });
  }
  /*++++++++++++++++++++++++++++++
   * +++++++ Message Handle ++++++
   * +++++++++++++++++++++++++++++
   */
  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }
}); //document

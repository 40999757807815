/*jshint esversion: 6 */

//var p = '';
//p = navigator.platform;

$(document).ready(function() {
  "use strict";

  /*++++++++++++++++++++++++++++++
   * +++++++ send-ajax-form ++++++
   * +++++++++++++++++++++++++++++
   */

  //rückruf Modal
  $(document).on('submit', '.send-recall-form', function() {
    $('.send-recall-form .add_repeat').html('<input type="text" name="repeat_email" />');
    var formHandle = '<input type="hidden" name="method" value="send_recall_formular" />';
    $('.send-recall-form .form-values').html(formHandle);
    var form_data = JSON.stringify($(this).serializeObject());
    send_formular(form_data);
    return false;
  });

  //kunden über uns Modal
  $(document).on('submit', '.send-user-message', function() {
    $('.send-user-message .add_repeat').html('<input type="text" name="repeat_email" />');
    var formHandle = '<input type="hidden" name="method" value="send_user_message" />';
    $('.send-user-message .form-values').html(formHandle);
    var form_data = JSON.stringify($(this).serializeObject());
    send_formular(form_data);
    return false;
  });

  //Formulare senden
  $(document).on('submit', '.send-ajax-formular', function() {
    //spamschutz
    $('.add_repeat').html('<input type="text" name="repeat_email" />');
    var formHandle = '<input type="hidden" name="method" value="' + $(this).attr('data-method') + '" />';
    formHandle += '<input type="hidden" name="form" value="' + $(this).attr('data-formtyp') + '" />';
    $('.form-values').html(formHandle);
    var form_data = JSON.stringify($(this).serializeObject());
    send_formular(form_data);
    return false;
  });

  //delete User-Message
  $(document).on('submit', "#data-message-delete-form", function() {
    $("#data-message-delete-form .form-values").html('<input type="hidden" name="method" value="delete_user_message" />');
    $('#data-message-delete-form .add_repeat').html('<input type="text" name="repeat_email" />');
    var form_data = JSON.stringify($(this).serializeObject());
    send_formular(form_data);
    return false;
  });

  //AJAX HANDLE
  function send_formular(form_data) {
    $.ajax({
      url: "ajax/form/formulare.php",
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(data) {
        if (data.status) {
          success_message(data.msg);
          if (data.formular) {
            $('.send-ajax-formular').trigger("reset");
          } else if (data.modal) {
            $("#IndexModal").modal('toggle');
          } else if (data.msg_delete) {
            $("#data-message-delete-form").empty();
            $(".del_msg").html('Eintrag erfolgreich aus der Datenbank gelöscht!');
          }
        } else {
          warning_message(data.msg);
        }
      },
      error: function(xhr, resp, text) {
        // show error to console
        console.log(xhr, resp, text);
      }
    });
  }

  /*+++++++++++++++++++++++++
   * +++++++ Serialize ++++++
   * ++++++++++++++++++++++++
   */
  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  /*++++++++++++++++++++++++++++++
   * +++++++ Message Handle ++++++
   * +++++++++++++++++++++++++++++
   */
  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

}); //document

/*jshint esversion: 6 */

$(document).ready(function($) {
  "use strict";

  $('#IndexModal').on('show.bs.modal', function(event) {
    var aufruf = $(event.relatedTarget);
    var type = aufruf.data('modaltyp');
    var body = '';
    var title = '';
    var button = '';
    $("#send-modal-form").removeClass();
    switch (type) {
      case 'recall':
        $("#send-modal-form").addClass('send-recall-form');
        title = '<i class="text-success fa fa-phone"></i>&nbsp; Rückrufservice';
        button = '<i class="fa fa-phone pr-2"></i>Senden';
        body += '<div class="col-md-8 offset-md-2">';
        body += '<div class="form-group">';
        body += '<label for="dsVorname"><i class="text-danger fa fa-asterisk fa-small"></i> Vorname</label>';
        body += '<input type="text" class="form-control" name="vorname" id="dsVorname" placeholder="Vorname" required>';
        body += '</div>';
        body += '<div class="form-group">';
        body += '<label for="dsNachname"><i class="text-danger fa fa-asterisk fa-small"></i> Nachname</label>';
        body += '<input type="text" class="form-control" name="nachname" id="dsNachname" placeholder="Nachname" required>';
        body += '</div>';
        body += '<div class="form-group">';
        body += '<label for="dsTelefon"><i class="text-danger fa fa-asterisk fa-small"></i> Telefon</label>';
        body += '<input type="text" class="form-control" name="telefon" id="dsTelefon" placeholder="Telefon" required>';
        body += '</div>';
        body += '<div class="form-group">';
        body += '<label for="dsEmail"><i class="text-danger fa fa-asterisk fa-small"></i> E-Mail</label>';
        body += '<input type="email" class="form-control" name="email" id="dsEmail" placeholder="E-Mail" required>';
        body += '</div>';
        body += '<div class="form-group">';
        body += '<label for="dsNachricht">Notiz</label>';
        body += '<textarea class="form-control" id="dsNachricht" name="message" rows="2"></textarea>';
        body += '</div>';
        body += '<div class="form-row pb-3">';
        body += '<div class="col-md-6 pb-3">';
        body += '<label for="telDate"><i class="text-danger fa fa-asterisk fa-small"></i> Gewünschte Rückrufzeit</label>';
        body += '<input type="date" name="datum" class="form-control" id="telDate" required>';
        body += '</div>';
        body += '<div class="col-md-6">';
        body += '<label for="SelTime">(Mo - Fr von 9 - 17 Uhr)</label>';
        body += '<select class="form-control" name="time" id="SelTime">';
        body += '<option value="0">Uhrzeit wählen...</option>';
        body += '<option value="9">09:00 Uhr</option>';
        body += '<option value="10">10:00 Uhr</option>';
        body += '<option value="11">11:00 Uhr</option>';
        body += '<option value="12">12:00 Uhr</option>';
        body += '<option value="13">13:00 Uhr</option>';
        body += '<option value="14">14:00 Uhr</option>';
        body += '<option value="15">15:00 Uhr</option>';
        body += '<option value="16">16:00 Uhr</option>';
        body += '<option value="17">17:00 Uhr</option>';
        body += '</select>';
        body += '</div>';
        body += '</div>';
        body += '<hr />';
        body += '<small class="d-block text-center"><i class="text-danger fa fa-asterisk fa-small"></i> Die Einträge Vorname, Nachname, Telefon, E-Mail und Rückrufzeit sind Pflichtfelder!</small>';
        body += '<hr />';
        body += '<div class="form-group form-check pt-2">';
        body += '<input type="checkbox" class="form-check-input" name="akzept" id="dsCheck2" required>';
        body += '<label class="form-check-label" for="dsCheck2">Ich akzeptiere die <a href="">Datenschutzbestimmung</a></label>';
        body += '</div>';
        body += '</div>';
        break;
      case'usrmsg':

      $("#send-modal-form").addClass('send-user-message');
      title = '<i class="text-success fa fa-list"></i>&nbsp; neuen Eintrag schreiben';
      button = '<i class="fa fa-list pr-2"></i>absenden';
      body += '<div class="col-md-10 offset-md-1">';

      body += '<div class="form-row">';
      body += '<div class="form-group col-md-6 pb-3">';
      body += '<label for="dsVorname"><i class="text-danger fa fa-asterisk fa-small"></i> Vorname</label>';
      body += '<input type="text" class="form-control" name="vorname" id="dsVorname" placeholder="Vorname" required>';
      body += '</div>';

      body += '<div class="form-group col-md-6 pb-3">';
      body += '<label for="dsNachname"><i class="text-danger fa fa-asterisk fa-small"></i> Nachname</label>';
      body += '<input type="text" class="form-control" name="nachname" id="dsNachname" placeholder="Nachname" required>';
      body += '</div>';
      body += '</div><!--row-->';

      body += '<div class="form-row">';
      body += '<div class="form-group col-md-6 pb-3">';
      body += '<label for="dsEmail"><i class="text-danger fa fa-asterisk fa-small"></i> E-Mail</label>';
      body += '<input type="email" class="form-control" name="email" id="dsEmail" placeholder="E-Mail" required>';
      body += '</div>';

      body += '<div class="form-group col-md-6 pb-3">';
      body += '<label for="dsWebseite">Webseite</label>';
      body += '<input type="text" class="form-control" name="homepage" id="dsWebseite" placeholder="Webseite">';
      body += '</div>';
      body += '</div><!--row-->';

      body += '<div class="form-group">';
      body += '<label for="dsNachricht"><i class="text-danger fa fa-asterisk fa-small"></i> Ihr Eintrag</label>';
      body += '<textarea class="form-control" id="dsNachricht" name="message" rows="4" placeholder="neuer Eintrag" required></textarea>';
      body += '</div>';

      body += '<hr />';
      body += '<small class="d-block"><i class="text-danger fa fa-asterisk fa-small"></i> Die Einträge Vorname, Nachname, E-Mail und Eintrag sind Pflichtfelder!</small>';
      body += '<hr />';
      body += '<small class="d-block"><i class="text-primary fa fa-info-circle"></i><br />Alle Einträge müssen vor der Veröffentlichung genehmigt werden! <br />';
      body += 'Nach dem absenden erhalten Sie eine E-Mail mit einem Link zum löschen Ihres Eintrages. </small>';
      body += '<hr />';
      body += '<div class="form-group form-check pt-4">';
      body += '<input type="checkbox" class="form-check-input" name="msg_akzept" id="dsCheck3" required>';
      body += '<label class="form-check-label" for="dsCheck3">Ich akzeptiere die <a href="">Datenschutzbestimmung</a></label>';
      body += '</div>';
      body += '</div>';
        break;
    }

    var modal = $(this);
    $(".modal-btn").html(button);
    modal.find('.modal-body').html(body);
    modal.find('.modal-title').html(title);
  });

}); //document

/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";

  var loc = window.location;
  var first_path = loc.pathname;
  var n = first_path.lastIndexOf("/") + 1;
  var seite = first_path.substr(n).replace('.php', '');

  switch (seite) {
    case 'user-profil':
      var client_id = $("#blog-show").attr('data-id');
      $.post("ajax/form/member-upload.php", {
          'ID': client_id,
          'METHOD': 'load_grop_image'
        },
        function(data) {
          var img_container = $("#user_profil_img");
          img_container.css("width", 120);
          img_container.css("height", 120);
          img_container.css("border-radius", "50%");
          var u = document.getElementById("user_profil_img");
          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, data.x, data.y, data.width, data.height, 0, 0, 120, 120);
          };
          img.src = data.image;
        });
      break;

    case 'user-startseite':
      $.post("ajax/form/member-upload.php", {
          'METHOD': 'load_grop_image'
        },
        function(data) {
          var img_container = $("#user_round_img");
          img_container.css("width", 120);
          img_container.css("height", 120);
          img_container.css("border-radius", "50%");
          img_container.css("border", "2px solid rgba(255, 255, 255, 0.5)");
          var u = document.getElementById("user_round_img");
          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, data.x, data.y, data.width, data.height, 0, 0, 120, 120);
          };
          img.src = data.image;
        });
      break;

    case 'blog-show':
      var blog_id = $("#blog-show").attr('data-id');
      $.getJSON("ajax/form/blog.php?m=read_blog_by_id&id=" + blog_id + " ", function(data) {
        $.each(data.kommentare, function(key, val) {
          var img_container = $('#kommentar_user_img' + val.id + '');
          img_container.css("width", 96);
          img_container.css("height", 96);
          img_container.css("border-radius", "50%");
          img_container.css("border", "2px solid rgba(255, 255, 255, 0.5)");
          var u = document.getElementById('kommentar_user_img' + val.id + '');
          u.width = u.scrollWidth;
          u.height = u.scrollHeight;
          var ctx = u.getContext("2d");
          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, val.x, val.y, val.width, val.height, 0, 0, 96, 96);
          };
          img.src = val.image;
        });
      });
      break;
  }

}); //document

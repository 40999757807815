/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";

  //WOWJS
  show_wow();

  var loc = window.location;
  var first_path = loc.pathname;
  var n = first_path.lastIndexOf("/") + 1;
  var seite = first_path.substr(n).replace('.php', '');
  var id = '';
  switch (seite) {
    case 'index':
      $("#home_nav").addClass('active');
      break;
    case 'startseite':
      $("#home_nav").addClass('active');
      break;
    case 'art-picture-galerie':
      $("#nav_galerie").addClass('active');
      id = getQueryVariable('id');
      $("#nav_sub_gal_" + id + "").addClass('active');
      break;
    case 'angebot-webdesign':
      $("#nav_webdesign").addClass('active');
      $("#nav_sub_webdesign").addClass('active');
      break;
    case 'webdesign':
      $("#nav_webdesign").addClass('active');
      $("#nav_sub_webdesign").addClass('active');
      break;
    case 'angebot-3d-visualisierung':
      $("#nav_webdesign").addClass('active');
      $("#nav_sub_3dVisual").addClass('active');
      break;
    case '3d-visualisierung':
      $("#nav_webdesign").addClass('active');
      $("#nav_sub_3dVisual").addClass('active');
      break;
    case 'blog':
      id = getQueryVariable('id');
      $("#nav_blog").addClass('active');
      $("#nav_sub_php" + id + "").addClass('active');
      break;
    case 'impressum':
      $("#nav_datenschutz").addClass('active');
      $("#nav_sub_impressum").addClass('active');
      break;
    case 'datenschutz':
      $("#nav_datenschutz").addClass('active');
      $("#nav_sub_datenschutz").addClass('active');
      break;
    case 'login':
      $("#nav_member").addClass('active');
      $("#nav_sub_login").addClass('active');
      break;
    case 'registrieren':
      $("#nav_member").addClass('active');
      $("#nav_sub_reg").addClass('active');
      break;
    case 'register_validate':
      $("#nav_member").addClass('active');
      $("#nav_sub_reg").addClass('active');
      break;
    case 'user-startseite':
      $("#nav_member").addClass('active');
      $("#nav_sub_usr_start").addClass('active');
      break;
    case 'change-passwort':
      $("#nav_member").addClass('active');
      $("#nav_sub_pw_change").addClass('active');
      break;
    case 'passwortvergessen':
      $("#nav_member").addClass('active');
      $("#nav_sub_pw").addClass('active');
      break;
    case 'blog-show':
      $("#nav_blog").addClass('active');
      break;
    case 'blog-archiv':
      $("#nav-blog").addClass('active');
      $("#nav_sub_archiv").addClass('active');
      break;
    case 'kontaktformular':
      $("#nav_kontakt").addClass('active');
      $("#nav_sub_kontakt").addClass('active');
      break;
    case 'kontakt':
      $("#nav_kontakt").addClass('active');
      $("#nav_sub_kontakt").addClass('active');
      break;
    case 'news-archive':

      break;
    case 'show-news':

      break;
    case 'links':

      break;
    case '404':

      break;
    case 'delete-account':

      break;
    case 'delete-message':

      break;
  }

  //GET ADDRESSE
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return (false);
  }

  function show_wow() {
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 100,
    mobile: true,
    live: true,
    callback: function(box) {
    },
    scrollContainer: null,
    resetAnimation: true,
  });
  wow.init();
}


}); //document

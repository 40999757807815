/*jshint esversion: 6 */
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import bootstrap from 'bootstrap';
import Cropper from 'cropperjs';
import swal from 'sweetalert';
//import { WOW } from 'wowjs';



$(document).ready(function() {
  "use strict";

  /*++++++++++++++++++++++++
   * +++++++ GAELERIE ++++++
   * +++++++++++++++++++++++
   */
  $("a[href$='.jpg'],a[href$='.png'],a[href$='.gif']").attr('data-gallery', '');
  $('#news-content img').addClass('img-fluid');
  $('.blog-box img').addClass('img-fluid');

  //srollTop
    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();

      } else {
        $('.scrollup').fadeOut();
     }
    });

      $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
           $('.scrollup').fadeIn();
      }
    });

    $('.scrollup').click(function() {
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });
      $('.scroll').click(function() {
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

}); //document

/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";

  //send-profil-NAME
  $(document).on('submit', "#send-profil-name", function() {
    $("#send-profil-name .form-values").html('<input type="hidden" name="method" value="edit_user_name" />');
    $('#send-profil-name .add_repeat').html('<input type="text" name="repeat_email" />');

    var form_data = JSON.stringify($(this).serializeObject());
    send_user_profil_data(form_data);
    return false;
  });

  //send-Email
  $(document).on('submit', "#send-profil-email", function() {
    $("#send-profil-email .form-values").html('<input type="hidden" name="method" value="edit_user_email" />');
    $('#send-profil-email .add_repeat').html('<input type="text" name="repeat_email" />');

    var form_data = JSON.stringify($(this).serializeObject());
    warn_change_email(form_data);
    return false;
  });

  //send-profil-about
  $(document).on('submit', "#send-profil-about", function() {
    $("#send-profil-about .form-values").html('<input type="hidden" name="method" value="user_update_about" />');
    $('#send-profil-about .add_repeat').html('<input type="text" name="repeat_email" />');

    var form_data = JSON.stringify($(this).serializeObject());
    send_user_profil_data(form_data);
    return false;
  });

  function send_user_profil_data(form_data) {
    $.ajax({
      url: 'ajax/form/user-profil.php',
      type: "POST",
      contentType: 'application/json',
      data: form_data,
      success: function(data) {
        if (data.status) {
          success_message(data.msg);
          if (data.username) {
            $(".edit-usr-name").text(data.vorname + ' ' + data.nachname);
          } else if (data.useremail) {
            $(".edit-usr-email").text(data.email);
          }
        } else {
          warning_message(data.msg);
        }
      },
      error: function(xhr, resp, text) {
        console.log(xhr, resp, text);
      }
    });
  }


  $(document).on('click', '#delete_user_account', function() {
    warn_delete_account();
  });

  function delete_user_account() {
    $.getJSON("ajax/form/delete-member.php", function(data) {
      if (data.status) {
        success_message(data.msg);
        var loc = window.location;
        var self_host = loc.host;
        var protocol = loc.protocol + '//';
        var first_path = loc.pathname;
        var n = first_path.indexOf("/") + 1;
        var seite = first_path.substr(n).replace('user-startseite.php', '');
        var self_url = protocol + self_host + '/' + seite + 'delete-account.php?delete=1';
        window.setTimeout('window.location = "' + self_url + '" ', 3000);
      }
    });
  }

  function warn_delete_account() {
    swal({
      icon: "info",
      title: "Account wirklich löschen?",
      text: "Alle Daten und Kommentare werden gelöscht!",
      buttons: {
        cancel: "abbrechen",
        senden: {
          text: "löschen",
          value: 'delete'
        }
      }
    }).then((value) => {
      switch (value) {
        case "delete":
          //funktion aufrufen
          delete_user_account();
          break;
      }
    });
  }

  function warn_change_email(form_data) {
    swal({
      icon: "info",
      title: "E-Mail wirklich ändern?",
      text: "Du kannst Dich erst nach der bestätigung wieder einloggen!",
      buttons: {
        cancel: "abbrechen",
        senden: {
          text: "E-Mail ändern",
          value: 'delete'
        }
      }
    }).then((value) => {
      switch (value) {
        case "delete":
          //funktion aufrufen
          send_user_profil_data(form_data);
          break;
      }
    });
  }

  //Message Handle
  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  /*+++++++++++++++++++++++++++++++++
   * +++++++ Serialize ++++++
   * ++++++++++++++++++++++++++++++++
   */
  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };
}); //document
